import React from 'react'
import './comman.css';

import { NavLink } from 'react-router-dom';
const Home = () => {

  return (
    <>

      <section>
        <div className='container-fluid p-0 position-relative ' style={{ backgroundImage: `url(${require("../img/PS1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', height: "100vh", border: "none" }}>
          <div className='position-absolute  text-white' style={{ bottom: "18%", left: "5%" }} >
            <h2 className='fontFamilyHeader1 fw-bold' style={{ fontSize: "4.8rem", lineHeight: "0.97" }}>Innovative <br></br>  breeding</h2>
            <p className='mt-4 pt-2 fontFamilyHeader1' style={{ fontSize: "1.4rem" }} >Get healthy, efficient, trouble-free cows with the highest <br></br>
              milk production and lowest use of antibiotics and hormones.</p>
            <div className=' pt-4'>
              <NavLink className='' to='/contactus' ><button className='  text-white fs-4 fontFamilyHeader1 READMOREBUTTON' style={{ border: "3px solid white", width: "38%", height: "58px" }}>Contact us</button></NavLink>
            </div>
          </div>

         
        </div>
      </section>

      
      <div className='colo fontFamilyHeader1'>
        <marquee > <h1 className=' '>  PASHUMITRA &nbsp; ANIMALS  &nbsp; HEALTH  &nbsp; CARE  &nbsp;PVT LTD.</h1></marquee>
      </div>

      <section>
        <div className=' container mt-3 p-5 ' style={{ border: "2px solid #25291C" }}>
          <div className="col-lg-12">
            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="false">
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row p-3">
                    <div class="col-8  mx-auto ">
                      <h4 class="mb-4 fontFamilyHeader">P CAL FORTE&#8482;</h4>
                      <p class="mb-0 pb-3 fontFamilyHeader1 " style={{ fontSize: "18px" }}>
                        Improve Milk Production <br />
                        Formation of Strong Bones in Growing Animal <br />
                        Improving Immunity
                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item ">
                  <div class="row p-3">
                    <div class="col-8  mx-auto ">
                      <h4 class="mb-4 fontFamilyHeader">P LIV LIVER TONIC&#8482;</h4>
                      <p class="mb-0 pb-3 fontFamilyHeader1" style={{ fontSize: "18px" }}>
                        The P Liv stimulates liver & improves it's functioning. <br />
                        The P Liv improves blood production. <br />
                        P Liv reduces morbidity. <br />
                        It improves milk quality especially milk fat & protein content. <br />
                        P Liv improves immunity

                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item ">
                  <div class="row p-3">
                    <div class="col-8  mx-auto ">
                      <h4 class="mb-4 fontFamilyHeader">AI FORTE SUPER&#8482;</h4>
                      <p class="mb-0 pb-3 fontFamilyHeader1" style={{ fontSize: "18px" }}>
                        Stimulate Follicular Growth <br />
                        Helps in Timely Ovulation <br />
                        Prevents Repeat Breeding Due to Early Embryonic death <br />
                        Helps in Efficient Energy Utilization <br />
                        Helps to Combat Stress

                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item ">
                  <div class="row p-3">
                    <div class="col-8  mx-auto ">
                      <h4 class="mb-4 fontFamilyHeader">AI FORTE GLYCENE&#8482;</h4>
                      <p class="mb-0 pb-3 fontFamilyHeader1" style={{ fontSize: "18px" }}>
                        To improve fertility <br />
                        To improve general health and immunity  <br />
                        To improve milk production & fat percentage

                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item ">
                  <div class="row p-3">
                    <div class="col-8  mx-auto ">
                      <h4 class="mb-4 fontFamilyHeader">BUFFTONE&#8482;</h4>
                      <p class="mb-0 pb-3 fontFamilyHeader1" style={{ fontSize: "18px" }}>
                        Stabilized rumen PH & bind mycotoxin. <br />
                        Enhances dry matter intake & fiber digestibility. <br />
                        Enhance energy from feeds via microbial digestion. <br />
                        Enhance milk yield & milk fat. <br />
                        Prevents condition like lameness, heat stress, mastitis, diarrhoea, poor BCS due to acidosis.

                      </p>
                    </div>
                  </div>
                </div>

              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span class="carousel-control-prev-icon bg-dark" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next " type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span class="carousel-control-next-icon bg-dark" aria-hidden="true"></span>
                <span class="visually-hidden ">Next</span>
              </button>
            </div>
          </div>
        </div>
      </section>

    

      <section>
        <div className="container-fluid mt-5 mb-5 " style={{ backgroundColor: "#25291C", }}>
          <div className='container' >
            <div className='row  d-flex justify-content-around align-items-center'  >
              <div className='col-lg-6 text-white  ' >
                <div className='p-4'  >
                  <div className='align-middle p-4' style={{ border: "2px solid white" }}>
                    <div className=' p-3 overflow-auto' style={{height:"530px"}}>
                      <h2 className='mb-4 fontFamilyHeader' style={{ fontSize: "30px" }}>PASHUMITRA HELATH CARE PVT LTD</h2>

                      <p className='   SolutionDropDownFontFamily' style={{ fontSize: "18px" }} >
                        <i> We at cattle feeds manufacture and suppliers, mainly deals with the supply of all types feed; in particular, we are instrumental in supplying complete range of animal feeds like buffalo feeds, sheep feeds and several other types of livestock feeds. All these products are manufactured using high quality ingredients and fine grade raw materials.   <br />Whether you're looking for best cattle feeds manufacturers in maharashtra, or need a reliable Cattle Feeds supplier in India, we have it all. With over a decade of experience, we have developed strong partnerships with top cattle feed brands and have strong reach over the Indian market. <br />Cattle feeds is the lead provider of animal feed products, manufactured in India. We are a pioneer in providing high quality, safe & environmental friendly animal feeds to the Indian cattle, poultry and pet markets. </i></p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-6  p-0'>
                <div className=''>
                  <img className='w-100 ' src={require("../img/pb.png")} alt='' />
                  {/* <img className='w-100' src="https://pashumitra.in/static/media/vgv.jpg" alt="" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div>
          <div className='container p-0 mt-5 mb-5 text-center' style={{ border: "2px solid white", backgroundColor: "", color: "black" }}>
            <div className='m-4 '>
              <h1 className='p-3 fontFamilyHeader'>WHY PASHUMITRA</h1>
              <p className='p-3 fontFamilyHeader1' style={{ fontSize: "27px" }} >"Pashumitra is one of the major providers of a wide range of cattle feed and agricultural products in maharashtra. such as cattle farming, poultry farming and small scale agriculture in maharashtra are done with the help of these products"</p>
            </div>
          </div>
        </div>

      </section>





    </>
  )
}

export default Home