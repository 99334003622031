import React from 'react'
import { NavLink } from 'react-router-dom';
import "./comman.css"

const FooTer = () => {

  return (
    <>

      {/* <footer className=" text-lg-start text-white"
        style={{ backgroundColor: "#1b4734" }} >
        <div className="container p-4 ">
          <section className="">

            <div className="row">

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  PASUMITRA ANIMAL HELATH CARE
                </h6>
                <p>
                  "Your animal's health is our passion. Choose Pashumitra for all your veterinary needs and see the difference it can make."
                </p>
              </div>
              <div className="col-md-4 col-lg-2 col-xl-2 mx-auto mt-3 ">
                <h6 className="text-uppercase mb-4 font-weight-bold">
                  Useful NavLinks
                </h6>
                <p>
                  <NavLink className="text-white text-decoration-none footerTarget" exact to="/">HOME</NavLink>
                </p>
                <p>
                  <NavLink className="text-white text-decoration-none footerTarget" exact to="/about">ABOUT US</NavLink>
                </p>
                <p>
                  <NavLink className="text-white text-decoration-none footerTarget" exact to="/contactus">CONTACT US</NavLink>
                </p>
                <p>
                  <NavLink className="text-white text-decoration-none footerTarget" exact to="/product">PRODUCT </NavLink>
                </p>
              </div>

              <hr className="w-100 clearfix d-md-none" />

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3 ">
                <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
                <p><i className="fas fa-home mr-3"></i> Gat no.267, Near Vitthal Mandir, Moi, Khed taluka, Maharashtra 410501</p>
                <p><i className="fas fa-envelope mr-3"></i> pashumitra@gmail.com</p>
                <p><i className="fas fa-phone mr-3"></i> + 91 9921792104</p>
              </div>
            </div>
          </section>


          <hr className="my-3" />


          <section className="p-3 pt-0">
            <div className="row d-flex align-items-center">

              <div className="col-md-2 col-lg-8 text-md-start">

                <div className="p-3">

                  © 2023 Copyright:

                  <NavLink className="text-white text-decoration-none" to={{ pathname: "https://pashumitra.in" }} target="_blank">&nbsp;  Pashumitra Animal Health Care</NavLink>  &nbsp;

                  All Rights Reserved.

                </div>

              </div>



              <div className="col-md-5 col-lg-4 ml-lg-0 text-md-end">
                <a className="btn btn-outline-light bg-primary btn-floating m-1 text-white" href="https://m.me/" ><i className="fab fa-facebook-f"></i></a>
                <a className="btn btn-outline-light bg-success  btn-floating m-1" href="https://wa.me/9921792104" aria-label="whatsapp" rel="noopener" ><i className="fab fa-whatsapp"  > </i></a>
              </div>
            </div>
          </section>
        </div>
      </footer> */}

      <section>
        <div className="container-fluid pt-lg-3 pb-3 " style={{ backgroundColor: "#25291C" }}>
          <div className="container  pt-lg-5">
            <div className="row p-lg-3 p-5 d-flex justify-content-center">
             
              <div className="col-lg-3 p-0 ms-lg-5 text-white">
                <div className='' >
                  <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white mt-2' style={{ fontSize: "19px" }} >PASUMITRA ANIMAL HELATH CARE</h6>
                </div>
                <div className=' mt-3' style={{ borderTop: "2px solid white",  }}  >
                  <nav className='h1FontFamily1 ' style={{ fontSize: "16px",  }}>
                    <p className='mt-3'>"Your animal's health is our passion. Choose Pashumitra for all your veterinary needs and see the difference it can make."</p>
                  </nav>
                </div>

              </div>
              <div className="col-lg-3 p-0 ms-lg-5 text-white ">
                <div className='' >
                  <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white mt-2' style={{ fontSize: "21px" }} >USEFUL NAVLINKS</h6>
                </div>
                <div className='w-75 mt-3' style={{ borderTop: "2px solid white", fontSize: "18px" }}  >
                  <nav className='h1FontFamily1 ' style={{ fontSize: "17px",  }}>
                    <div className='mt-3 fontFamilyHeader1 ' style={{ fontSize: "17px", lineHeight: "17px" }}>
                      <NavLink to='/' className='text-decoration-none '> <p className='FOOTERLINK'>HOME</p></NavLink>
                      <NavLink to='/about' className='text-decoration-none '> <p className='FOOTERLINK'>ABOUT US</p></NavLink>
                      <NavLink to='/Product' className='text-decoration-none '> <p className='FOOTERLINK'>PRODUCTS</p></NavLink>
                      <NavLink to='/contactus' className='text-decoration-none '> <p className='FOOTERLINK'>CONTACT US</p></NavLink>
                    </div>
                  </nav>
                </div>
              </div>

              {/* <div className="col-lg-2  text-white">
                <div className='' >
                  <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-white' style={{ fontSize: "21px" }}>More BMW Websites</h6>
                </div>
                <div className='w-75 mt-3' style={{ borderTop: "2px solid white", fontSize: "18px" }}  >
                  <nav className='h1FontFamily1 ' style={{ borderTop: "1px solid hsla(0,0%,73%,.2)", fontSize: "17px" }} >
                    <div className='mt-3 fontFamilyHeader1' style={{ fontSize: "21px", lineHeight: "17px" }}>
                      <NavLink to='/login' className='text-decoration-none ' style={{ color: "#8e8e8e" }} ><p className='FOOTERLINK'>Login</p></NavLink>
                      <NavLink to='/register' className='text-decoration-none ' style={{ color: "#8e8e8e" }} ><p className='FOOTERLINK'>Register</p></NavLink>
                      <NavLink to='' className='text-decoration-none ' style={{ color: "#8e8e8e" }} ><p className='FOOTERLINK'>Account</p></NavLink>
                    </div>
                  </nav>
                </div>
              </div> */}

              <div className='col-lg-4 p-0 '>
                <div>
                  <h6 className='SolutionDropDownFontFamily2 text-decoration-none text-center text-white pb-3 mt-2' style={{ fontSize: "23px" }}>Contact With Us</h6>
                </div>
                <div className=' mt-3'   >
                  <div className=' text-center'>
                    {/* <NavLink className='text-decoration-none text-white ' target='_blank' to='https://www.google.com/maps/dir//Offices+No+601,+6th+Floor,+iCloudsoft+Technology,+Lotus+,+Opp+Joshi+Railway+Museum,+Kothrud,+Pune,+Maharashtra+411038/@18.5013998,73.781925,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2bf285b81dd75:0x344ffe59f81b4da1!2m2!1d73.8200338!2d18.5013998'><i className="fa-solid fa-location-dot fs-2 mb-2"></i> </NavLink> */}
                    <h6 className=' mt-3  SolutionDropDownFontFamily text-white' style={{fontSize:"19px"}} ><i className='fas fa-home me-3'></i>Gat no.267, Near Vitthal Mandir, Moi,Tal. Khed, Maharashtra 410501</h6>
                  </div>
                </div>
                <div className='row d-flex justify-content-center pt-4 mt-3'>
                  <div className='col-lg-2 col-3 text-center '>
                    <NavLink to="https://www.pashumitra.in/ " target="_blank"><i className="fa-brands fa-facebook fs-2 text-white"></i></NavLink>
                  </div>

                  <div className='col-lg-2 col-3 text-center'>
                    <NavLink to='https://www.pashumitra.in/' target="_blank"><i className="fa-brands fa-linkedin fs-2 text-white"></i> </NavLink>
                  </div>

                  <div className='col-lg-2 col-3 text-center '>
                    <NavLink to='https://wa.me/9921792104' target="_blank"><i class="fa-brands fa-whatsapp fs-2 text-white"></i> </NavLink>
                  </div>

                  <div className='col-lg-2 col-3 text-center '>
                    <NavLink to='https://www.pashumitra.in/' target="_blank"><i className="fa-brands fa-instagram fs-2 text-white"></i> </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-center mt-5 text-white  h1FontFamilyFooter ' >
              © 2023 Copyright:  Pashumitra Animal Health Care   All Rights Reserved.
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FooTer;