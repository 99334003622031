import React from 'react'

import PLiv from "../img/P-Liv.png"
import Pcal from "../img/P-Cal.png"
import { NavLink } from 'react-router-dom'

const Product = () => {
    const sTwCal = () => {
        alert("open Whatsapp")
        const msg = "P CAL regared prdouct enquiry"
        window.open("https://wa.me/9921792104?text=" + msg, '_blank').focus();
    }
    const sTwAI = () => {
        alert("open Whatsapp")
        const msg = "AI FORTE SUPER regared prdouct enquiry"
        window.open("https://wa.me/9921792104?text=" + msg, '_blank').focus();
    }
    const sTwLiv = () => {
        alert("open Whatsapp")
        const msg = "P LIV regared prdouct enquiry"
        window.open("https://wa.me/9921792104?text=" + msg, '_blank').focus();
    }
    return (
        <>
         

            <section>
                <div className='container-fluid  p-0' style={{ backgroundImage: `url(${require("../img/productBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '65%,0%', height: "100vh", border: "none" }}>
                </div>
                <div className='container-fluid  p-2   ' style={{ backgroundColor: "#25291C", color: "white" }}>
                    <div className='text-center '>
                        <h3 className='SolutionDropDownFontFamily' style={{ fontSize: "45px" }} >Products</h3>
                        <p className=' SolutionDropDownFontFamily' style={{ fontSize: "22px" }} >"Your animal's health is our top priority. Pashumitra is committed to providing the highest quality veterinary products and services."</p>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className='container mt-3 p-3  '>
                        <div className="row p-3 pt-5 pb-5 shadow-lg">
                            <div className="col-md-4" >
                                <img className='w-75 ' src={Pcal} alt="" />
                                <div className='mt-2'>
                                    <b className='text-success fs-5 fontFamilyHeader1'>Dosage :</b>
                                    <p className='mt-2 fontFamilyHeader1'>Cattle 100-200 ml <br />
                                        Calves 20ml. twice daily <br />
                                        Sheep, Goat & Pigs 20 ml daily</p>
                                </div>
                            </div>
                            <div className='col-md-8 pt-5 '>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='text-center'>
                                            <h1 className='fontFamilyHeader1'>P CAL FORTE&#8482;</h1>
                                            <h3 className='mt-3 mb-3 fontFamilyHeader1'>Animals Feed Supplement of Vitamins & Calcium </h3>
                                        </div>
                                        <table className='w-100 text-center table'>
                                            <thead >
                                                <tr className='fontFamilyHeader1 fs-5'>
                                                    <th>Usage</th>
                                                    <th>Package</th>
                                                    <th>Brand</th>
                                                    <th>Form</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='SolutionDropDownFontFamily '>
                                                    <td >Cattle</td>
                                                    <td> 1/5/10/20 lit.</td>
                                                    <td>P Cal </td>
                                                    <td>Liquid</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="col-md-4" style={{ fontSize: "19px" }} >
                                            <div>
                                                <div className='SolutionDropDownFontFamily'>
                                                    <b className='text-success'>WHAT IS P CAL FORTE&#8482;?</b>
                                                    <p>
                                                        <b>P CAL FORTE&#8482;</b> is a Animals Feed Supplement of Vitamins & Calcium <br /> Animals Feed Supplement Not For Human Use, Not For Medicinal Use.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4" style={{ fontSize: "19px" }} >
                                            <div className='SolutionDropDownFontFamily'>
                                                <b className='text-success ' style={{ fontSize: "18px" }} >WHY CHOOSE P-CAL FORTE?</b>
                                                <p>
                                                    <b> Important Information: </b> <br />
                                                    A Nutritional Liquid feed supplement of Calcium, Phosphorus,
                                                    Vitamin D3, Vitamin C & Vitamin B Complex for Animals
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4" style={{ fontSize: "19px" }} >
                                            <div className='SolutionDropDownFontFamily'>
                                                <b className='text-success'>Benefits</b>
                                                <p> "P CAL FORTE&#8482; is not just a supplement, it's a promise of strong bones, healthy growth, and better immunity for your beloved animals."</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 mt-3">
                                    <div className="row">
                                        <div className='col-md-6'>
                                            <button type="submit" onClick={sTwCal} class="btn BUYBUTTON text-white shadow SolutionDropDownFontFamily w-100 " value="Send">Buy Product</button>
                                        </div>
                                        <div className='col-md-6'>
                                        <NavLink to = '/contactus' ><button type="submit" class="btn  PRODUCTCONTACTBUTTON shadow SolutionDropDownFontFamily w-100"  value="Send">Contact Us</button></NavLink>                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className='container mt-3 p-3 '>
                        <div className="row p-3 pt-5 pb-5 shadow-lg">
                            <div className="col-md-4" >
                                <img className='w-75 ' src={Pcal} alt="" />
                                <div className='mt-2'>
                                    <b className='text-success fontFamilyHeader1 fs-5'>Dosage :</b>
                                    <p className='fontFamilyHeader1'>20 gms Daily for 25 days</p>
                                </div>
                            </div>
                            <div className='col-md-8  p-2'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='text-center'>
                                            <h1 className='fontFamilyHeader1'>AI FORTE SUPER&#8482;</h1>
                                            <h3 className='mt-3 mb-3 fontFamilyHeader1'>Animals Feed Supplement of Mineral Mixture </h3>
                                        </div>
                                        <table className='w-100 text-center table'>
                                            <thead>
                                                <tr className='fontFamilyHeader1 fs-5'>
                                                    <th>Usage</th>
                                                    <th>Package</th>
                                                    <th>Brand</th>
                                                    <th>Form</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='SolutionDropDownFontFamily'>
                                                    <td >Cattles</td>
                                                    <td> 1/5/20 Kg </td>
                                                    <td>AI FORTE SUPER&#8482; </td>
                                                    <td>Powder</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="col-md-4" style={{ fontSize: "19px" }}>

                                            <div className='SolutionDropDownFontFamily'>
                                                <b className='text-success'>WHAT IS AI FORTE SUPER&#8482;?</b>
                                                <p>
                                                    <b>AI FORTE SUPER&#8482;</b> is a Animals Feed Supplement of Mineral Mixture <br /> Animals Feed Supplement Not For Human Use, Not For Medicinal Use.</p>
                                            </div>

                                        </div>
                                        <div className="col-md-4 " style={{ fontSize: "19px" }}>
                                            <div className='SolutionDropDownFontFamily'>
                                                <b className='text-success'>WHY CHOOSE AI FORTE SUPER&#8482;?</b>
                                                <p>
                                                    <b> Important Information: </b> <br />
                                                    Omega-3 & Omega-6 Fatty Acids Along with Organic
                                                    Minerals and Vitamins for Efficient Reproduction in Dairy Cattle.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4" style={{ fontSize: "19px" }}>

                                            <div className='SolutionDropDownFontFamily'>

                                                <p><b className='text-success'>Benefits</b> "AI FORTE SUPER&#8482; is more than just a mineral mixture supplement. It's a game-changer for animal nutrition, delivering the essential nutrients they need for optimal growth and performance."</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 mt-3">
                                    <div className="row">
                                        <div className='col-md-6'>
                                            <button type="submit" onClick={sTwAI} class="btn BUYBUTTON btn-primary shadow  w-100" value="Send">Buy Product</button>
                                        </div>
                                        <div className='col-md-6'>
                                        <NavLink to = '/contactus' ><button type="submit" class="btn  PRODUCTCONTACTBUTTON shadow SolutionDropDownFontFamily w-100"  value="Send">Contact Us</button></NavLink>                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className='container mt-3 p-3 '>
                        <div className="row p-3 pt-5 pb-5 shadow-lg">
                            <div className="col-md-4" >
                                <img className='w-25 ' src={PLiv} alt="" />
                                <div className='mt-2'>
                                    <b className='text-success fontFamilyHeader1 fs-5'>Dosage :</b>
                                    <p className='fontFamilyHeader1'>Large animal : 50 to 100 ml day ,
                                        Small animal : 20 to 25 ml daily <br />
                                        Sheep, Goat & Pig : 10 to 15 ml per day ,
                                        Pups and Cats : 5 ml twice daily <br />
                                        Adult Dog/Bitches : 20 ml twice a day ,
                                        For each 100 birds <br />
                                        Chicks : 5 ml twice a day ,
                                        Grover : 20 ml daily <br />
                                        Layer : 50ml daily ,
                                        Or as directed by Veterinarian </p>
                                </div>
                            </div>

                            <div className='col-md-8  p-2'>
                                <div className='container'>
                                    <div className='row '>
                                        <div className='text-center'>
                                            <h1 className=' fontFamilyHeader1'> P LIV LIVER TONIC&#8482; </h1>
                                            <h3 className='mt-3 mb-3 fontFamilyHeader1'>Animals Feed Supplement of Liver Tonic </h3>
                                        </div>
                                        <table className='w-100 text-center table'>
                                            <thead>
                                                <tr className='fontFamilyHeader1 fs-5'>
                                                    <th>Usage</th>
                                                    <th>Package</th>
                                                    <th>Brand</th>
                                                    <th> From</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='SolutionDropDownFontFamily'>
                                                    <td >Cattles</td>
                                                    <td> 500ml/1/5 lit.</td>
                                                    <td>P-Liv </td>
                                                    <td>Liquid</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="col-md-4" style={{ fontSize: "19px" }} >

                                            <div className='SolutionDropDownFontFamily'>
                                                <b className='text-success'>WHAT IS P LIV LIVER TONIC&#8482;?</b>
                                                <p>
                                                    <b>P Liv</b> is an Animals Feed Supplement of Liver Tonic <br /> Animals Feed Supplement Not For Human Use, Not For Medicinal Use..</p>
                                            </div>

                                        </div>
                                        <div className="col-md-4" style={{ fontSize: "19px" }} >
                                            <div className='SolutionDropDownFontFamily'>
                                                <b className='text-success'>WHY CHOOSE P LIV LIVER TONIC&#8482;?</b>
                                                <p>
                                                    <b> Important Information: </b> <br />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4" style={{ fontSize: "19px" }} >
                                            <div className='SolutionDropDownFontFamily'>
                                                <b className='text-success'>Benefits</b>
                                                <p>"Healthy liver, happy pet - give your animal the best chance at a fulfilling life with P LIV LIVER TONIC&#8482;."</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 mt-3">
                                    <div className="row">
                                        <div className='col-md-6'>
                                            <button type="submit" onClick={sTwLiv} class="btn BUYBUTTON btn-primary shadow  w-100" value="Send">Buy Product</button>
                                        </div>
                                        <div className='col-md-6'>
                                        <NavLink to = '/contactus' ><button type="submit" class="btn  PRODUCTCONTACTBUTTON shadow SolutionDropDownFontFamily w-100"  value="Send">Contact Us</button></NavLink>                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className='container mt-3 p-3  '>
                        <div className="row p-3 pt-5 pb-5 shadow-lg">
                            <div className="col-md-4" >
                                <img className='w-75 ' src={Pcal} alt="" />
                                <div className='mt-2'>
                                    <b className='text-success fontFamilyHeader1 fs-5'>Dosage :</b>
                                    <p className='fontFamilyHeader1'>50-100 gm per animal per day <br />
                                        1.0 to 2.0% in compound feed  or <br />
                                        as recommended by nutritionist</p>
                                </div>
                            </div>
                            <div className='col-md-8  p-2'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='text-center'>
                                            <h1 className='fontFamilyHeader1'>AI FORTE GLYCENE&#8482; </h1>
                                            <h3 className='mt-3 mb-3 fontFamilyHeader1'>Animals Feed Supplement Nutrients </h3>
                                        </div>
                                        <table className='w-100 text-center table'>
                                            <thead>
                                                <tr className='fontFamilyHeader1 fs-5'>
                                                    <th>Usage</th>
                                                    <th>Package</th>
                                                    <th>Brand</th>
                                                    <th>Form</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='SolutionDropDownFontFamily'>
                                                    <td >Cattle</td>
                                                    <td> 1/5/20 Kg</td>
                                                    <td>AI FORTE GLYCENE&#8482; </td>
                                                    <td>Powder</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="col-md-4" style={{ fontSize: "19px" }} >
                                            <div className='SolutionDropDownFontFamily'>
                                                <b className='text-success'>WHAT IS AI FORTE GLYCENE&#8482;?</b>
                                                <p>
                                                    <b>AI FORTE GLYCENE&#8482; </b> is a Animals Feed Supplement of Nutrients <br /> Animals Feed Supplement Not For Human Use, Not For Medicinal Use.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4" style={{ fontSize: "19px" }} >
                                            <div className='SolutionDropDownFontFamily'>
                                                <b className='text-success'>WHY CHOOSE AI FORTE GLYCENE&#8482; ?</b>
                                                <p>
                                                    <b> Important Information: </b> <br />
                                                    A Nutrients For Dairy Animals
                                                    with methochelated minerals & live yeasts

                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4" style={{ fontSize: "19px" }} >
                                            <div className='SolutionDropDownFontFamily'>
                                                <b className='text-success'>Benefits</b>
                                                <p> "Strong animals, strong health and immunity, strong milk - ultimate feed supplement."</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 mt-3">
                                    <div className="row">
                                        <div className='col-md-6'>
                                            <button type="submit" onClick={sTwCal} class="btn BUYBUTTON btn-primary shadow  w-100" value="Send">Buy Product</button>
                                        </div>
                                        <div className='col-md-6'>
                                            <NavLink to = '/contactus' ><button type="submit" class="btn  PRODUCTCONTACTBUTTON shadow SolutionDropDownFontFamily w-100"  value="Send">Contact Us</button></NavLink>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className='container mt-3 p-3 pb-5 '>
                        <div className="row p-3 pt-5 pb-5 shadow-lg">
                            <div className="col-md-4" >
                                <img className='w-75 ' src={Pcal} alt="" />
                                <div className='mt-2'>
                                    <b className='text-success fontFamilyHeader1'>Dosage :</b>
                                    <p className='fontFamilyHeader1'>50-100 gm per animal per day <br />
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-8  p-2'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='text-center'>
                                            <h1 className=' fontFamilyHeader1'>BUFFTONE&#8482;</h1>
                                            <h3 className='mt-3 mb-3 fontFamilyHeader1'>Animals Feed Supplement Minerals </h3>
                                        </div>
                                        <table className='w-100 text-center table'>
                                            <thead>
                                                <tr className='fontFamilyHeader1 fs-5'>
                                                    <th>Usage</th>
                                                    <th>Package</th>
                                                    <th>Brand</th>
                                                    <th>Form</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='SolutionDropDownFontFamily'>
                                                    <td >Cattle</td>
                                                    <td> 300 mg</td>
                                                    <td>BUFFTONE&#8482; </td>
                                                    <td>Powder</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="col-md-4" style={{ fontSize: "19px" }}>
                                            <div className='SolutionDropDownFontFamily'>
                                                <b className='text-success'>WHAT IS BUFFTONE&#8482;?</b>
                                                <p>
                                                    <b>BUFFTONE&#8482;</b> is a Animals Feed Supplement of Minerals <br /> Animals Feed Supplement Not For Human Use, Not For Medicinal Use.</p>
                                            </div>

                                        </div>
                                        <div className="col-md-4" style={{ fontSize: "19px" }}>
                                            <div className='SolutionDropDownFontFamily'>
                                                <b className='text-success'>WHY CHOOSE BUFFTONE&#8482;?</b>
                                                <p>
                                                    <b> Important Information: </b> <br />
                                                    A unique blend of minerals & herbs for dairy animals with  the power of live yeast culture(Saccharomyces Cerevisiae)
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-4" style={{ fontSize: "19px" }}>
                                            <div className='SolutionDropDownFontFamily'>
                                                <b className='text-success'>Benefits</b>
                                                <p> "For stronger bones, healthier digestion, and improved immunity - choose Bufftone."</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 mt-3">
                                    <div className="row">
                                        <div className='col-md-6'>
                                            <button type="submit" onClick={sTwCal} class="btn btn-primary shadow BUYBUTTON  w-100" value="Send">Buy Product</button>
                                        </div>
                                        <div className='col-md-6'>
                                            <button type="submit" class="btn  PRODUCTCONTACTBUTTON shadow SolutionDropDownFontFamily w-100" value="Send">Contact Us</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>


    )
}

export default Product