import React, { useRef, useState } from 'react'
import './comman.css'
import { NavLink } from 'react-router-dom';


const Contact = () => {
    const [Fname, setFname] = useState();
    const [Email, setEmail] = useState();
    const [Number, setNumber] = useState();
    const [Message, setMessage] = useState();
    const [errors, setErrors] = useState({});
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
            errors.Fname = ""; errors.Number = ""; errors.Message = "";

            window.open("https://wa.me/9921792104?text=" + + "NAME:-" + Fname + "=MOBILE NUMBER" + Number + "EMAIL ADDRESS" + Email + "MESSAGE" + Message, '_blank').focus();
            setEmail(""); setFname(""); setNumber(""); setMessage("");
            alert("your information send succefully ")
        }
        else {
            setErrors(errors);
        }
    };
    const validateForm = () => {
        const errors = {};
        if (!Fname) {
            errors.Fname = 'Name is required';
        }
        else if (Fname.length < 4) {
            errors.Fname = "Name Must have 4 Char";
        }
        if (!Number) {
            errors.Number = 'Number is required';
        }
        else if (Number.length !== 10) {
            errors.Number = 'Number must be 10 digit';
        }
        if (!Email) {
            errors.Email = "Email is required"
        }
        if (!Message) {
            errors.Message = 'Message is required';
        }

        return errors;
    };



    return (
        <>
            <section>
                <div className='container-fluid  position-relative p-0' style={{ backgroundImage: `url(${require("../img/pashumitraCONTACT.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '0% 50%', height: "103vh", border: "none" }}>
                    <div className='  text-white position-absolute ' style={{ right: "30%", bottom: "40%" }}>
                        <h2 className='SolutionDropDownFontFamily fw-bold' style={{ fontSize: "45px" }} ><i>Love to hear from you,</i></h2>
                        <h2 className='h1FontFamily1 ' style={{ fontSize: "66px" }} >Get in Touch</h2>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-5  p-lg-5 text-center mt-lg-3" >
                    {/* <div className='container '  > */}
                        <div className="row g-2 p-0">
                            <div className='col-md-6  p-0'>
                                <div className='m-0'  >
                                    <iframe className='w-100 m-0' title="gmap"style={{ height: "550px",borderRadius:"10px 0px 0px 10px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241778.13746344537!2d73.72115011640628!3d18.763209699999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ca1e5c13fd41%3A0x83ac34a4ffeab2f4!2sPashuMitra!5e0!3m2!1sen!2sin!4v1676619302342!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">

                                    </iframe>
                                </div>
                            </div>

                            <div className='col-md-6 shadow-lg ' style={{ height: "550px",borderRadius:"0px 10px 10px 0px"  }}>
                                <h2 className=' pt-3 fontFamilyHeader1' style={{ fontSize: "24px" }} >Lets Get in Touch <br></br>We are interested in talking about your queries</h2>
                                <form class="row g-5  p-lg-5 ms-lg-5 ps-lg-5 " ref={form} onSubmit={sendEmail}>
                                    <div class="col-md-12 ">
                                        <input type="text" className="form-control" style={{ width: "100%" }} id="inputText1" placeholder='Fullname' name='Fname' onChange={e => setFname(e.target.value)} value={Fname} />
                                        {errors.Fname && <div className='text-danger'>{errors.Fname}</div>}
                                    </div>

                                    <div className="col-12">
                                        <div className="row">
                                            <div class="col-6">
                                                <input type="number" class="form-control "  id="inputNumber" placeholder="Number" name='number' onChange={e => setNumber(e.target.value)} value={Number} />
                                                {errors.Number && <div className='text-danger'>{errors.Number}</div>}
                                            </div>

                                            <div class="col-6">
                                                <input type="email" class="form-control "  id="inputEmail4" placeholder='Email address' name='email' onChange={e => setEmail(e.target.value)} value={Email} />
                                                {errors.Email && <div className='text-danger'>{errors.Email}</div>}
                                            </div>
                                        </div>
                                    </div>


                                    <div className='col-12'>
                                        <textarea type="text" class="form-control "  id="inputText3" placeholder='Message' name='message' onChange={e => setMessage(e.target.value)} value={Message} />
                                        {errors.Message && <div className='text-danger'>{errors.Message}</div>}
                                    </div>

                                    <div class="col-12">
                                        <button type="submit" class="btn btn border contactBUTTON w-50" value="Send">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </section>

            <section>
                <div className="container-fluid">
                    <div className='p-5  mt-lg-4 ' >
                        <div className='container text-center '>
                            <div className='row '>
                                <div className="col-lg-4">
                                    <div className='text-center'>
                                        <i className="fa-solid fa-phone text-center fs-4 mb-3"></i>
                                    </div>

                                    <h4 className='fs-4 text-center h1FontFamily'>Call Us</h4>
                                    <NavLink className='text-decoration-none text-dark SolutionDropDownFontFamily' to='tel:9921792104'> <h6 className='mt-3 fs-5 ps-4 pe-4'> +91 9921792104</h6></NavLink>
                                </div>

                                <div className="col-lg-4 ">
                                    <div className='text-center'>
                                        <i className="fa-solid fa-location-dot fs-2 mb-2"></i>
                                    </div>

                                    <h4 className=' fs-4 h1FontFamily'>Address</h4>
                                    <h6 className='fs-5  text-center SolutionDropDownFontFamily'>Gate no.267, Near Vitthal Mandir, Moi, Chakan Khed, Pune Maharashtra - 410501 </h6>
                                </div>
                                <div className="col-lg-4">
                                    <div className='text-center'>
                                        <i className="fa-solid fa-envelope fs-2 mb-2"></i><br />
                                    </div>
                                    <h4 className='fs-4 text-center h1FontFamily'>Email</h4>
                                    <NavLink className='text-decoration-none text-dark SolutionDropDownFontFamily' to="mailto:pashumitraa@gmail.com"> <h6 className='text-center fs-5'>pashumitraa@gmail.com</h6></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <div>

            </div>
        </>

    )
}

export default Contact;
