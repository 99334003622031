import React from 'react'
import './comman.css'
const About = () => {
    return (
        < >
            <section >
                <div className='container-fluid  p-0' style={{ backgroundImage: `url(${require("../img/ps2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', height: "100vh", border: "none" }}>
                   
                </div>
                <div className='container-fluid  p-2   ' style={{ backgroundColor: "#25291C", color: "white" }}>
                    <div className='text-center '>
                        <h3 className='SolutionDropDownFontFamily' style={{ fontSize: "45px" }} >About Us</h3>
                        <p className=' SolutionDropDownFontFamily' style={{ fontSize: "22px" }} >"Your animal's health is our top priority. Pashumitra is committed to providing the highest quality veterinary products and services."</p>
                    </div>
                </div>
            </section>

            <section>
                <div className='container mt-3 p-5 '>
                    <div className='row'>
                        {/* <div className="col-lg-1"></div> */}
                        <div className="col-lg-6">
                            <h4 className='fontFamilyHeader1 fw-bold' style={{ fontSize: "40px" }} >WELCOME TO PASHUMITRA</h4>
                            <div className='mt-3 SolutionDropDown' style={{ fontSize: "19px" }} >
                                <p>Pashumitra is one of the largest providers of cattle feed and agricultural products in Maharashtra. We have been helping farmers with their cattle farming, poultry farming, and small scale agriculture needs for over 10 years. Our products are of the highest quality and are backed by our team of experts who are always available to help you get the most out of our products.</p>
                                <p> Pashumitra was established in 2010 with the goal of providing a wide range of cattle feed and agricultural products to farmers in Maharashtra. We provide products that are essential for cattle farming, poultry farming, and small-scale agriculture. Our products are of the highest quality and are designed to help our customers be successful in their farming endeavors.</p>
                                <p> We are proud to be one of the leading providers of cattle feed and agricultural products in Maharashtra. We are committed to helping our customers achieve their goals and improve their operations. We offer a wide range of products that are essential for successful farming. Contact us today to learn more about our products and how we can help you!</p>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className='' data-aos="fade-left" >
                                <img className='w-100 ' src={require("../img/ps4.png")} alt='works-at-iclodsoft' />
                            </div>
                        </div>
                        {/* <div className="col-lg-1"></div> */}
                    </div>

                </div>
            </section>




            <section>
                <div className="container-fluid" style={{ backgroundColor: "#25291C" }} >
                    <div className="container  p-5 text-white">
                        <h2 className='text-center fontFamilyHeader1' style={{ fontSize: "37px" }} >Improving animal health  through innovative and sustainable products.</h2>
                        <div className="row mt-4 p-4" data-aos="fade-right" data-aos-duration="4000" data-aos-offset="100">
                            <div className="col-lg-6">
                           
                                <div className='p-4 fontFamilyHeader1' style={{ fontSize: "19px" }} >
                                    <p>"At Pashumitra, our <b><i>vision</i></b> is to become the leading provider of high-quality animal healthcare products and services that promote the well-being and longevity of animals.</p>
                                    <p> We strive to make a positive impact on the animal health industry by manufacturing and selling innovative and effective nutrition and veterinary products that improve animal health and quality of life.</p>
                                    <p> We aim to be a trusted partner for animal owners and veterinarians, delivering expert knowledge and guidance to help them make informed decisions about their animal's health. Our commitment to animal welfare and sustainability drives us to develop products that are safe, environmentally responsible, and socially conscious. Through our dedication to innovation and excellence, we aim to create a better world for animals, their owners, and the community."</p>
                                </div>

                            </div>

                            <div className="col-lg-1"></div>

                            <div className="col-lg-5 ">
                            
                                <div className='p-4 mt-4' style={{ backgroundColor: "#685F4B" }} >
                                    <div className='SolutionDropDownFontFamily3 fw-bold' style={{ backgroundColor: "#685F4B", border: "1px solid white", padding: "30px" }} >
                                        <i>
                                            <p>At Pashumitra, our <b>mission</b> is to provide the highest quality animal healthcare products and services to improve the health and well-being of all animals. We are committed to the health and happiness of every animal, and we believe that by providing excellent veterinary care and nutrition, we can help them lead longer, healthier, and more fulfilling lives.</p>
                                            <p>We are united in our passion to help producers "Feed the Future" and provide every opportunity for a farming sectors that is sustainable for grazing livestock through the production and supply of high quality feed ingredients, feed technology and agronomic support.</p>
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section >
                <div className="container-fluid ">
                    <div className='container mt-3 p-3 '>
                        <div className='row'>
                            <div className="text-center">
                                <h1 className='p-3 fontFamilyHeader1 ' style={{ fontSize: "35px" }} >VALUE PROPOSTION</h1>
                                <div className='SolutionDropDownFontFamily' style={{ fontSize: "23px" }} >
                                    <p>Cattle feed, Poultry feed and Agricultural Products in maharashtra: Pashumitra as one of the major cattle feed and agricultural products suppliers in maharashtra, is a leading name when it comes to offering a wide range of poultry and cattle feed. Cattle farming, poultry farming and small scale agriculture in maharashtra are done with the help of these products.  We have decades' worth of experience in dealing with cattle feeding, poultry feeding, small scale agriculture farming and horticulture. And that is something, we are extremely proud of.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About