import React from 'react'
import { NavLink } from 'react-router-dom'
import './comman.css'


const NavBar = () => {





    return (
        <>

            <nav className="navbar navbar-expand-lg    header-content   " >
                <div className=" container w-100">
                    <div className=''>
                        <img className='w-100' style={{  height:"5rem" }} src={require("../img/logo1.png")} alt="" />
                    </div>
                    <button className="navbar-toggler " type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa-solid fa-bars text-white"></i>
                    </button>
                    <div className="collapse navbar-collapse   " id="navbarNavDropdown">
                        <ul className="navbar-nav  fontFamilyHeader1 w-100   ms-lg-3"  >
                            <li className='nav-item ' >
                                <NavLink className="text-dark text-decoration-none text-white" to="/"> HOME </NavLink>
                            </li>
                            <li className='nav-item  ' >
                                <NavLink className="text-dark text-decoration-none  text-white " to="/about">ABOUT US </NavLink>
                            </li>
                            <li className='nav-item  ' >
                                <NavLink className="text-dark text-decoration-none  text-white" to="/Product"> PRODUCT </NavLink>
                            </li>
                            <li className='nav-item  ' >
                                <NavLink className="text-dark text-decoration-none  text-white" to="/contactus">CONTACT US </NavLink>
                            </li>
                            <li className='nav-item  ' >
                                <NavLink className="text-dark text-decoration-none  text-white" to="tel:+91 9921792104"><i className="fa-solid fa-phone"></i> +91 9921792104 </NavLink>
                            </li>

                        </ul>

                    </div>
                    <div className='mt-lg-3'  >
                        <div id="google_translate_element"></div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default NavBar