import React from 'react'
import { Routes, Route } from 'react-router'
// import NavBar from './NavBar'
import Home from './Home'
import Contact from './Contact'
import About from './About'
import Product from './Product'
import FooTer from './FooTer'
import NavBar from './NavBar'
import './comman.css'
const Routing = () => {
    return (
        <>
            <React.Fragment>
                <header className='position-sticky ' style={{zIndex:1}} >
                    <NavBar/>
                </header>
                <main className='mainMargin'  >
                    <Routes>
                        <Route exact to path="/" element={<Home />} />
                        <Route exact to path="*" element={<Home />} />
                        <Route exact to path="/contactus" element={<Contact />} />
                        <Route exact to path="/about" element={<About />} />
                        <Route exat to path="/Product" element={<Product />} />
                    </Routes>
                </main>
                <footer>
                    <FooTer />
                </footer>


            </React.Fragment>

        </>
    )
}

export default Routing